.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
}

.App-header {
  width: 100%;
  max-width: 480px;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

input, textarea {
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box; /* Makes padding not affect width */
}

button {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 12px;
  cursor: pointer;
  width: 100%;
  border-radius: 4px;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
}
